<!--
 * @Descripttion: 
 * @version: 
 * @Author: Hanbaka
 * @Date: 2024-08-13 20:03:04
 * @LastEditors: Hanbaka
 * @LastEditTime: 2024-08-13 22:14:28
-->
<template>
  <interKnot/>
</template>

<script>
import interKnot from './components/inter-knot.vue';

export default {
  name: 'App',
  components: {
    interKnot
  }
};
</script>

<style>
</style>

